import * as firebase from "firebase";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_CONSOLE_APIKEY,

    authDomain: "beegreene-ec8b9.firebaseapp.com",

    projectId: "beegreene-ec8b9",

    storageBucket: "beegreene-ec8b9.appspot.com",

    messagingSenderId: "412921712216",

    appId: "1:412921712216:web:c773229c7bd0b5d0c6042d"

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// export
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
